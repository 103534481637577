<template>
  <div class="p-4 w-full h-full">

  </div>
</template>


<script>


export default {
  name: 'Main',
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>